/*
- AS_SetLayout
-- sets the theme (dark mode) and the layout (PO vs Pcom)
-- for now only fires in router.afterEach
*/

import config from '@/config';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('AS_SetLayout');
		
		if(store.state.api.token === ''){
			// Public is the Unauthenticated Pcom layout
			store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);

			// remove any theme classes
			document.documentElement.setAttribute('data-bs-theme', 'light');
			document.body.classList.remove('theme-serrena');
		} else {
			// App is the Authenticated PO layout (assume default)
			store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
			
			document.body.classList.remove('bg-simple');
			
			if(store.state.customer.themeId !== 0){
				// there was value in local storage already for the theme, use that
				switch(store.state.customer.themeId){
					case 0:	// none (reset to normal)
					case config.enums.Theme.REGULAR:
						document.documentElement.setAttribute('data-bs-theme', 'light');
						document.body.classList.remove('theme-serrena');
						break;
					case config.enums.Theme.DARK:
						document.documentElement.setAttribute('data-bs-theme', 'dark');
						document.body.classList.remove('theme-serrena');
						break;
					case config.enums.Theme.SERRENA:
						document.documentElement.setAttribute('data-bs-theme', 'light');
						document.body.classList.add('theme-serrena');	
						break;
				}
			}
		}

		resolve();
	
	});//e:Promise			
}
