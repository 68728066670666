/*
- AS_LoadDatepicker
-- Sets any date picker object 
-- mainly i need this so i can get the date from an API call (sync) and then inject it into the UI of the DatePicker Fields asynchronously 
-- 2024.04.23
*/

import _has from 'lodash/has';
import config from '@/config';
import dateFormat from "dateformat";

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('AS_LoadDatepicker');

		const _$opts = $opts || {};
		const _isIsoString = (_has(_$opts, 'isIsoString')) ? _$opts.isIsoString : false;
		const _isPaperEdit = (_has(_$opts, 'isPaperEdit')) ? _$opts.isPaperEdit : false;
		const _isRefWizard = (_has(_$opts, 'isRefWizard')) ? _$opts.isRefWizard : false;
		const _loadDate = (_has(_$opts, 'loadDate')) ? _$opts.loadDate : null;

		if(!_loadDate || _loadDate === '01/01/2099'){
			return false;
		}

		// grab elements in the dom by their id
		let $elDay = document.getElementById($opts.id + '_Day');
		let $elMonth = document.getElementById($opts.id + '_Month');
		let $elYear = document.getElementById($opts.id + '_Year');

		if($elDay && $elMonth && $elYear){
			if(_isIsoString){
				// i was passed an ISO string (2022-01-09T00:00:00)
				
				// get the first 10 chracters, then split it at on the dash ['2022', '01', '09']
				let dateArray = _loadDate.substring(0, 10).split('-');
				
				$elMonth.value = dateArray[1];
				$elDay.value = dateArray[2];
				$elYear.value = dateArray[0];

			} else {
				if(_isRefWizard){
					if(_loadDate.length === 4 || _loadDate.length === 5) {
						// Year Only (4 for year / 5 for year plus letter)
						$elYear.value = _loadDate;

					} else {
						// Not year only, but figure out what you have
						let dateArray = _loadDate.split('/');

						if(dateArray.length === 2){
							// assume MM/YYYY
							$elMonth.value = dateArray[0];
							$elDay.value = '';
							$elYear.value = dateArray[1];

						} else {
							// assume full date
							$elMonth.value = dateArray[0];
							$elDay.value = dateArray[1];
							$elYear.value = dateArray[2];

						}//e:if:else
					}//e:if:else
		
				} else {
					// NOT Reference Wizard
				
					// parse the load date from long format into MM, DD, YYYY object
					const listMonthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		
					let dateString = '';
					let setMonthValue = '';
					let setDayValue = '';
					let setYearValue = '';
		
					// Bools that set to true only when there isn't a matching bit (ie: No Day listed)
					let isNoMonth = false;
					let isNoDay = false;
					let isNoYear = false;
					
					switch($opts.paperFormat){
						case config.enums.Format.APA6:
						case config.enums.Format.APA7:
						case config.enums.Format.Turabian9:
							// remove the , from the long date string (and any trailing spaces)
							if(_isPaperEdit){
								let dateArrayEdit = _loadDate.trim().split('/');
								
								let fakeDateObj = new Date();
		
								switch(dateArrayEdit.length){
									case 1: // Only One
										if(dateArrayEdit[0].length > 3){
											// Year
											fakeDateObj.setFullYear(dateArray[0]);
										} else {
											// Month (assumed)
											fakeDateObj.setMonth(dateArrayEdit[0] - 1);
										}
										break;
		
									case 2: // Only Two
										// Check the 1st bit
										if(dateArrayEdit[0].length > 3){
											// Year
											fakeDateObj.setFullYear(dateArray[0]);
										} else {
											// Month (assumed)
											fakeDateObj.setMonth(dateArrayEdit[0] - 1);
										}
		
										// Check the 2nd bit
										if(dateArrayEdit[1].length > 3){
											// Year
											fakeDateObj.setFullYear(dateArrayEdit[1]);
										} else {
											// Day (assumed)
											fakeDateObj.setDate(dateArrayEdit[1]);
										}
										break;
		
									case 3: // All Three (MM/DD/YYYY)
										fakeDateObj.setFullYear(dateArrayEdit[2]);
										fakeDateObj.setDate(dateArrayEdit[1]);
										fakeDateObj.setMonth(dateArrayEdit[0] - 1);
										break;
								}
		
								setMonthValue = dateFormat(fakeDateObj, 'mm');
								setDayValue = dateFormat(fakeDateObj, 'dd');
								setYearValue = dateFormat(fakeDateObj, 'yyyy');
		
								$elMonth.value = setMonthValue;
								$elDay.value = setDayValue;
								$elYear.value = setYearValue;
								
							} else {
								dateString = _loadDate.trim().replace(/[,]+/g, "");
							}
							break;

						case config.enums.Format.MLA8:
						case config.enums.Format.MLA9:
							dateString = _loadDate;
							break;
					}//e:switch
					
					// not sure how this could happen but keep a check on it just incase
					if(dateString === ''){
						return false;
					}
		
					// split into array
					let dateArray = dateString.split(' ');
					
					// console.log('dateArray');
					// console.log(dateArray);
		
					// set each date item
					let fakeDateObj = new Date();
					switch($opts.paperFormat){
						case config.enums.Format.APA6:
						case config.enums.Format.APA7:
						case config.enums.Format.Turabian9:
							// APA [month, day, year]
							switch(dateArray.length){
								case 1:
									// Single Bit (Month || Day || Year)
									if(isNaN(dateArray[0])){
										// Month
										isNoDay = true;
										isNoYear = true;
										fakeDateObj.setMonth(listMonthsFull.indexOf(dateArray[0]));
									} else {
										// (Day || Year)
										isNoMonth = true;
										if(dateArray[0].length > 3){
											// Year
											isNoDay = true;
											fakeDateObj.setFullYear(dateArray[0]);
										} else {
											// Day
											isNoYear = true;
											fakeDateObj.setDate(dateArray[0]);
										}
									}
									break;
								case 2:
									// First Bit (Month || Day || Year)
									if(isNaN(dateArray[0])){
										// First Bit is Month
										fakeDateObj.setMonth(listMonthsFull.indexOf(dateArray[0]));
										
										// Second Bit (Day || Year)
										if(dateArray[1].length > 3){
											// Second Bit is Year
											isNoDay = true;
											fakeDateObj.setFullYear(dateArray[1]);
										} else {
											// Second Bit is Day
											isNoYear = true;
											fakeDateObj.setDate(dateArray[1]);
										}
									} else {
										// First Bit is Day - Second is Year
										isNoMonth = true;
										fakeDateObj.setDate(dateArray[0]);
										fakeDateObj.setFullYear(dateArray[1]);
									}
									break;
								case 3:
									// Full Date [month,day,year]
									fakeDateObj.setMonth(listMonthsFull.indexOf(dateArray[0]));
									fakeDateObj.setDate(dateArray[1]);
									fakeDateObj.setFullYear(dateArray[2]);
									// console.log('fakeDateObj');
									// console.log(fakeDateObj);
									break;
							}
							break;
						case config.enums.Format.MLA8:
						case config.enums.Format.MLA9:
							// MLA [day,month,year]
							if(dateString.includes('/')){
								// assume it's PaperEdit > Overview Tab > Edit Modal
								let mlaDateArray = dateString.split('/');
								fakeDateObj.setMonth(mlaDateArray[0] - 1);
								fakeDateObj.setDate(mlaDateArray[1]);
								fakeDateObj.setFullYear(mlaDateArray[2]);
		
							} else {
								// assume it's a Paper Edit > Editor Tab > Title Page Panel
								fakeDateObj.setMonth(listMonthsFull.indexOf(dateArray[1]));
								fakeDateObj.setDate(dateArray[0]);
								fakeDateObj.setFullYear(dateArray[2]);
		
							}
						
							break;
					}
		
					// values havn't been set yet
					if(setMonthValue === '' && setDayValue === '' && setYearValue === ''){
						$elMonth.value = (isNoMonth) ? '' : dateFormat(fakeDateObj, 'mm');
						$elDay.value = (isNoDay) ? '' : dateFormat(fakeDateObj, 'dd');
						$elYear.value = (isNoYear) ? '' : dateFormat(fakeDateObj, 'yyyy');
					}
				
				}//e:if:else:isRefWizard

			}//e:if:else:_isIsoString

			// emit to the datepicker component, to set it's date
			window.$vm.emitter.emit('setDatePickerDate');

		}//e:if:$elX
		resolve();
		
	});//e:Promise			
}
