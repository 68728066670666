/*
- AS_InitTooltips
-- inits BS5 Tooltips
-- 2022.10.14
*/

import Tooltip from 'bootstrap/js/dist/tooltip';

export default () => {
	return new Promise((resolve) => {
		// console.log('AS_InitTooltips');

		// let the ui catch up so there will be a tooltip rendered
		setTimeout(()=>{
			const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
			const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
				new Tooltip(tooltipTriggerEl, {
					trigger : 'hover'
				})
			});

			resolve();
		}, 500);

	});//e:Promise

}