/*
- AS_ValidateForm
-- 2024.04.23
*/

import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';	//https://www.npmjs.com/package/validator
import isNumeric from 'validator/lib/isNumeric';
import store from '@/store';
import config from '@/config';

export default ($opts) => {
	return new Promise((resolve, reject) => {
		// console.log('AS_ValidateForm');

		const _$opts = $opts || {};
		const _singleFieldId = (_has(_$opts, 'singleFieldId')) ? _$opts.singleFieldId : '';
		const _formId = (_has(_$opts, 'formId')) ? _$opts.formId : '';

		let isValid = true;

		let parentFormId = _formId;
		
		// clone the validation array
		let _validation = _cloneDeep(store.state.forms.validation);
		
		// Loop through vuex store object for validation
		_validation.forEach((validationData)=>{
			if(
				(_formId === '' || validationData.formId === _formId) &&
				(_singleFieldId === '' || validationData.fullId === _singleFieldId)
			){
				
				validationData.isShown = false;
				
				if(validationData.validateEnum === config.enums.Validation.DATE){
					// this is a datepicker field so it follows different validation rules

					// grab elements in the dom by their id
					let $elDay = document.getElementById(validationData.fullId + '_Day');
					let $elMonth = document.getElementById(validationData.fullId + '_Month');
					let $elYear = document.getElementById(validationData.fullId + '_Year');

					if($elDay && $elMonth && $elYear){
						// all the elements exist, now check their values
						if(
							$elDay.value == '' ||
							$elMonth.value == '' ||
							$elYear.value == ''
						){
							isValid = false;
							validationData.isShown = true;
						}
					}
				} else {
					// NOT a date type, so check normal fields
					// get field by it's full id
					let $field = document.getElementById(validationData.fullId);
					parentFormId = validationData.formId;

					if($field){
						switch(validationData.validateEnum){
							case config.enums.Validation.EMAIL:
								if(!isEmail($field.value)){
									isValid = false;
									validationData.isShown = true;
								}
								break;

							case config.enums.Validation.EMAIL_REQUIRED:
								if(_isEmpty($field.value) || !isEmail($field.value)){
									isValid = false;
									validationData.isShown = true;
								}
								break;
							
							case config.enums.Validation.NUMERIC:
								if(!isNumeric($field.value) || parseInt($field.value) === 0){
									isValid = false;
									validationData.isShown = true;
								}
								break;

							case config.enums.Validation.NUMERIC_REQUIRED:
								if(_isEmpty($field.value) || !isNumeric($field.value) || parseInt($field.value) === 0){
									isValid = false;
									validationData.isShown = true;
								}
								break;
							
							case config.enums.Validation.REQUIRED:
								// can i determine the type by the field tag?
								if($field.tagName.toLowerCase() === 'input' || $field.tagName.toLowerCase() === 'textarea'){
									if(_isEmpty($field.value)){
										isValid = false;
										validationData.isShown = true;
									}
								} else if($field.tagName.toLowerCase() === 'select'){
										// for <select> forms count "0" as the default/none answer
									if($field.value === "0") {
										isValid = false;
										validationData.isShown = true;
									}
								}
								break;
						}
					}
				}
				
			}
		});//e:forEach

		// check the cloned validation array back in
		store.commit('forms/SET_VALIDATION', _validation);

		// with everything else checked, lets see if there is a zip code (from the Cart)
		let isZipCodeValidated = false;

		if(_formId === 'formCartPaymentSettings'){
			// validate zip code first
			let chkIsNonUsAddress = document.getElementById('chkIsNonUsAddress');
			if(chkIsNonUsAddress){
				if(chkIsNonUsAddress.checked){
					// Non Use Address - don't check zip
					isZipCodeValidated = true;
				} else {
					// US Address - check zip
					let txtZipCode = document.getElementById('_PID_CartZipCode');
					// console.log(txtZipCode);

					if(txtZipCode){
						let zipCodeValue = txtZipCode.value;
						if(zipCodeValue === ''){
							// trigger error state on this zipcode
							txtZipCode.classList.add('is-invalid');

							// has an error already been shown
							let txtZipCodeError = document.getElementById('_PID_CartZipCodeError');
							
							if(!txtZipCodeError){
								let errorHtml = `<div class="invalid-feedback d-block mt-1" id="_PID_CartZipCodeError">
									Please enter numeric Zip Code
								</div>`;

								txtZipCode.insertAdjacentHTML('afterend', errorHtml);
							}
							
						} else {
							isZipCodeValidated = true;
						}

					} else {
						isZipCodeValidated = true;

					}//e:txtZipCode
				}//e:chkIsNonUsAddress.checked
			} else {
				isZipCodeValidated = true;
			}//e:chkIsNonUsAddress

		} else {
			// form wont have zip code 
			isZipCodeValidated = true;

		}//e:f_formId

		if(isValid && isZipCodeValidated){
			store.commit('forms/MARK_FORM_VALID_BY_ID', {
				formId: parentFormId
			});
			
			return resolve();

		} else {
			store.commit('forms/MARK_FORM_INVALID_BY_ID', {
				formId: parentFormId
			});
			
			return reject();
			
		}
	
	});//e:Promise
}