/*
- AS_StoreSyncData
-- Stores the data from a sync call
-- 2023.11.24
*/

import _has from 'lodash/has';
import config from '@/config';
import { isBefore } from 'date-fns';
import { parseISO } from 'date-fns';
import RS_Loop from '@/services/reference/loop';
import RS_Prepare from '@/services/reference/prepare';
import store from '@/store';

export default (responseData) => {
	return new Promise((resolve) => {
		// console.log('AS_StoreSyncData');
		
		if(config.platformId === config.enums.Platform.CHROME_EXT){
			chrome.storage.local.set({
				'lastSyncDate': responseData.lastSyncDate
			});
		}

		// Impersonating - clear local storage before logging in
		if(config.platformId !== config.enums.Platform.CHROME_EXT){
			if(responseData.customer.isImpersonating){
				localStorage.clear();
			}
		}

		if(config.platformId === config.enums.Platform.ONLINE){
			// convert the Paper AutoSave Value from the server enum into a usable ms value
			// let autoSaveMins = 5;	// enum 1 : 5 minutes default
			// switch (responseData.customer.paperAutoSave) {
			// 	case 0:
			// 		autoSaveMins = 0;
			// 		break;
			// 	case 2:
			// 		autoSaveMins = 10;
			// 		break;
			// 	case 3:
			// 		autoSaveMins = 15;
			// 		break;
			// 	case 4:
			// 		autoSaveMins = 20;
			// 		break;
			// 	case 5:
			// 		autoSaveMins = 25;
			// 		break;
			// 	case 6:
			// 		autoSaveMins = 30;
			// 		break;
			// 	case 7:
			// 		autoSaveMins = 1;
			// 		break;
			// 	case 8:
			// 		autoSaveMins = 3;
			// 		break;
			// }
			
			store.commit('classes/SET_LIST', responseData.classes);
			// store.commit('paperEdit/autoSave/SET_TIMER_MS', autoSaveMins * 60 * 1000);
			
			// this will force the auto save timer to fire in a full minute - no matter what the user has set
			store.commit('paperEdit/autoSave/SET_TIMER_MS', 60000);

		}//e:if:ONLINE

		store.dispatch('customer/setSiteTheme', {
			legacyThemeId: responseData.customer.themeID
		}).then(()=>{
			store.commit('references/RESET_UPDATE_AFTER_SYNC');	// clear this so when the next vue mounts it will be fresh

			if(config.platformId === config.enums.Platform.ONLINE){
				store.commit('reminders/SET_LIST', responseData.reminders);
				store.commit('work/SET_LIST', responseData.work);

				if(typeof zE === 'function'){
					zE('webWidget', 'prefill', {
						name: {
							value: store.state.customer.fullName,
						},
						email: {
							value: store.state.customer.email,
						},
					});
				}

				if(responseData.papers && responseData.addInPapers){
					let allPapersArray = responseData.papers.concat(responseData.addInPapers);
					
					// loop through all papers
					allPapersArray.forEach((paper)=>{
						// skip deleted papers or papers without an enctrypedID (Word Add-In)
						if(paper.isDeleted || !paper.encryptedID){
							return false;
						}
	
						let className = '';
						let dueDate = '';
						let isOnlinePaper = true;
						let paperID = 0;
	
						// Online
						dueDate = paper.dueDate || '';
						paperID = paper.paperID;
						
						// for some reason ClassName doesnt' come through in the paper - that's ok i can use the ID to look up the class name from the class Vuex object that populates on Sync
						if(paper.classID){
							let thisClass = store.state.classes.list.find((classData)=>{
								return classData.classID === paper.classID;
							});
							if(thisClass){
								className = thisClass.name
							}
						} 
						
						// console.log(paper);
	
						let paperData = {
							className: className,
							classID: paper.classID || '',
							createdDate: paper.createdDate || '',
							description: paper.description || '',
							dueDate: dueDate,
							encryptedID: paper.encryptedID || '',
							genesisReferences: paper.genesisReferences,
							isComplete: paper.isComplete,
							isDeleted: paper.isDeleted,
							isOnlinePaper: isOnlinePaper,
							lastModifiedDate: paper.lastModifiedDate || '',
							paperFormatID: paper.paperFormatID,
							paperFormatVersionID: paper.paperFormatVersionID,
							paperID: paperID,
							paperType: paper.paperType,
							references: paper.references,
							startDate: paper.startDate,
							title: paper.title,
						}
	
						let findPaperIndex = store.state.paperList.list.findIndex((paper) =>{
							return paper.paperID === paperID;
						});
	
						if(findPaperIndex != -1){
							// already added - update all it's data incase i've changed properties in paperData since list sync
							store.commit('paperList/UPDATE_PAPER_IN_LIST', {
								paperIndex: findPaperIndex,
								paperData: paperData
							});
						} else {
							// new addition
							store.commit('paperList/ADD_PAPER_TO_LIST', paperData);
						}
					});//e:forEach
				
				}//e:responseData.papers && responseData.addInPapers
			}//e:platform.ONLINE

			store.commit('api/SET_CUSTOMER_ID', responseData.customer.customerID.toString());

			if(config.useErrorLogger){
				window.$vm.$rollbar.configure({
					payload: {
						person: {
							id: responseData.customer.customerID,
							username: responseData.customer.fullName,
							email: responseData.customer.email
						}
					}
				});
			}

			if(config.usePendo && window.pendo){
				let calcFreeTrialConverstion = '';

				if(responseData.customer.role === 1){
					// free trial mode mark this as false until they convert
					calcFreeTrialConverstion = 'false';
				} else if(responseData.customer.role === 2){
					// full access - check the flag passed down from sync
					calcFreeTrialConverstion = responseData.customer.freeTrialConversion.toString();
				}

				// recalculate which exp date i'm going to use - i already do this to determine expired, but do it again for pendo
				let useExpirationDate;	// declare here and set in the next if block
				
				// check if user is in an organization
				if(responseData.customer.organizationID === 0){
					// NOT in an org - Check subscription expiration date
					useExpirationDate = new Date(responseData.customer.subscriptionExpirationDate);
				} else {
					// IN an org - use whichever expiration date is later
					if(isBefore(parseISO(responseData.customer.subscriptionExpirationDate), parseISO(responseData.customer.organizationExpirationDate))){
						// customer is before org - use customer
						useExpirationDate = new Date(responseData.customer.organizationExpirationDate);
					} else {
						// org is before customer - use org
						useExpirationDate = new Date(responseData.customer.subscriptionExpirationDate);
					}
				}
				
				pendo.initialize({
					visitor: {
						account_created_date: responseData.customer.createdDate,
						id: responseData.customer.customerID,
						email: responseData.customer.email,
						expiration_date: useExpirationDate,
						full_name: responseData.customer.fullName,
						institution: responseData.customer.institution,
						organization_id: responseData.customer.organizationID,
						renewal_type: (responseData.customer.isRecurring) ? 'Automatic' : 'Manual',
						status: config.enums.AccountRole[responseData.customer.role],
						subscription_period: config.enums.SubscriptionPeriod[responseData.customer.subscriptionType],
						freetrial_conversion: calcFreeTrialConverstion,	// should pass '' | 'true' | 'false'
						trial_end_date: responseData.customer.freeTrialExpirationDate
					},
					account: {
						id: 'PERRLA'
					}
				});
			}

			if(window.newrelic){
				newrelic.setCustomAttribute('Browser', store.state.api.browserName);
				newrelic.setCustomAttribute('BrowserVersion', store.state.api.browserVersion);
				newrelic.setCustomAttribute('CustomerID', store.state.api.customerId);
				newrelic.setCustomAttribute('IsImpersonating', store.state.customer.isImpersonating);
				newrelic.setCustomAttribute('OS', store.state.api.osName);
				newrelic.setCustomAttribute('OSVersion', store.state.api.osVersion);
				
				if(config.platformId === config.enums.Platform.ONLINE){
					newrelic.setCustomAttribute('SourceApp', 'Online');
					newrelic.addRelease('PERRLA Online SPA', VERSION);
				} else if(config.platformId === config.enums.Platform.ADD_IN){
					newrelic.setCustomAttribute('SourceApp', 'WordAddIn');
					newrelic.setCustomAttribute('WordVersion', Office.context.diagnostics.version);
				}
								
				newrelic.setCustomAttribute('SourceAppVersion', VERSION);
			}

			// prepare and loop through all references, once for non-genesis then again for genesis 
			// prep and loop can be combined into one
			// after that it would be cool to just have the service here without the need to call it twice
			RS_Prepare({
				'isGenesis': false
			}).then((currentRefLibArray)=>{
				// console.log('currentRefLibArray');
				// console.log(currentRefLibArray);
				RS_Loop({
					'currentRefLibArray': currentRefLibArray,
					'decompressReferences': responseData.references,
					'isGenesis': false
				}).then(()=>{
					// next move to genesis references
					RS_Prepare({
						'isGenesis': true
					}).then((currentGenRefLibArray)=>{
						// console.log('currentGenRefLibArray');
						// console.log(currentGenRefLibArray);
						RS_Loop({
							'currentRefLibArray': currentGenRefLibArray,
							'decompressReferences': responseData.genesisReferences,
							'isGenesis': true
						}).then(()=>{
							if(config.platformId === config.enums.Platform.ADD_IN ||config.platformId === config.enums.Platform.ONLINE){
								// process research notes
								store.state.referenceLibraryGenesis.forEach((referenceData)=>{
									// console.log(referenceData.isDeleted)
									if(_has(referenceData, 'researchNotes') && referenceData.researchNotes.length > 0){
										
										// moved to it's own Vuex store module 2023.02.15
										referenceData.researchNotes.forEach((researchNoteData)=>{
											if(researchNoteData.isDeleted){
												// Deleted - send the remove signal out, it checks to see if the note is already added or not
												store.commit('researchNotes/REMOVE_BY_UNIQUE_ID', researchNoteData.genesisResearchNoteUniqueID); 

											} else {
												// Not Deleted
												// check if this RN is in already saved in the vuex
												let findIndexTest = store.state.researchNotes.list.findIndex((findRearchNoteData)=>{
													return findRearchNoteData.genesisResearchNoteUniqueId.toUpperCase() === researchNoteData.genesisResearchNoteUniqueID.toUpperCase();
												});
												if(findIndexTest === -1){
													// fresh add
													let citationOutput = '';

													if(researchNoteData.citation !== ''){
														
														let citationResultObject = JSON.parse(researchNoteData.citation);
														// assume APA7 for now
														if(_has(citationResultObject, 'apa7') && _has(citationResultObject.apa7, 'first') && citationResultObject.apa7.first !== ''){
															citationOutput = citationResultObject.apa7.first;
														}
													}
													
													let addResearchNoteData = {
														citation: researchNoteData.citation,
														citationOutput: citationOutput,
														genesisReferenceId: referenceData.referenceID,
														genesisResearchNoteId: researchNoteData.genesisResearchNoteID,
														genesisResearchNoteUniqueId: researchNoteData.genesisResearchNoteUniqueID,
														note: researchNoteData.note,
														referenceUniqueID: referenceData.referenceUniqueID,
													}

													store.commit('researchNotes/ADD_TO_LIST', addResearchNoteData);
												}
											}
										});//e:forEach

										delete referenceData.researchNotes;	// no longer need this property so delete it
									}
									
								});//e:forEach
								
							}//e:if:ADD_IN||ONLINE

							// console.log('store.state.referenceLibraryGenesis');
							// console.log(store.state.referenceLibraryGenesis);
							return resolve();
						});//e:RS_Loop(genesis)
					});//e:RS_Prepare(genesis)

				});//e:RS_Loop

			});//e:RS_Prepare(regular)

		});//e:customer/setSiteTheme
	});
}