/*
- ErrS_GenericAlert
-- since removing the Blue Spinner, I need a way to stop the user from working on their paper if there was a save error
-- this file for now is only used when a saving error occurs
-- it should stop all user interactions / saves
*/
import _has from 'lodash/has';
import config from '@/config';
import Swal from 'sweetalert2';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('ErrS_GenericAlert');
		
		const _$opts = $opts || {};
		const _enum = (_has(_$opts, 'enum')) ? _$opts.enum : config.enums.Error.PAPER_SAVE;
		const _src = (_has(_$opts, 'src')) ? _$opts.src : 'Unknown';

		if(_enum === config.enums.Error.PAPER_SAVE);{
			let alertText = 'There was a problem saving your document.';

			console.log('Error Source: ' + _src);

			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Error',
				text: alertText,
				icon: 'error',
				confirmButtonText: 'OK',
				showCloseButton: false,
				showCancelButton: false,
				showConfirmButton: true,
				customClass:{
					confirmButton: 'btn btn-outline-danger',
				},
			}).then((result) => {
				if (result.value) {
					location.reload();
				}//e:result.value
			});//e:Swal.fire

			resolve();	
		}//e:if:PAPER_SAVE

	});//e:Promise			
}
