/*
- ErrS_Reroute
-- 2022.09.22
*/
import _has from 'lodash/has';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';

export default ($opts) => {
	return new Promise((resolve, reject) => {
		// console.log('ErrS_Reroute');

		const _$opts = $opts || {};
		const _pushToName = (_has(_$opts, 'pushToName')) ? _$opts.pushToName : 'Dashboard';

		let alertText = 'There was a problem loading this item, please try again.';
		let confirmButtonText = 'Ok';

		// sweet alert 
		Swal.fire({
			allowOutsideClick: false,
			buttonsStyling: false,
			title: 'Error',
			text: alertText,
			icon: 'error',
			confirmButtonText: confirmButtonText,
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: true,
			customClass:{
				confirmButton: 'btn btn-outline-danger',
			},
		}).then((result) => {
			if (result.value) {
				store.commit('loaders/REMOVE_ID', 'App');

				if(_pushToName === 'X'){
					// close route modal
					window.$vm.emitter.emit('closeRouteModal')
				} else {
					// perform redirect
					router.push({
						name: _pushToName
					}).catch(()=>{});
				}
			}
		});

		resolve();
	});//e:Promise			
}
