/*
- AS_CheckToken
-- checks the users token, if need be generate another one and return it
-- 2023.07.06
*/
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import router from '@/router';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

export default () => {
	return new Promise((resolve) => {
		// console.log('AS_CheckToken');

		let passToken;	// only used in PERRLA Online SPA

		if(config.platformId === config.enums.Platform.ONLINE){
			passToken = localStorage.getItem("pc_PassToken");
		}

		if (config.platformId === config.enums.Platform.ONLINE && passToken) {
			// PassToken Set - copy it and remove it
			store.commit('api/SET_TOKEN', passToken);
			localStorage.removeItem("pc_PassToken");
			return resolve(passToken);

		} else {
			// PassToken not set or not in the ONLINE platform
			
			// check for New Relic GUID here
			if(store.state.api.sessionId === ''){
				// not set, generate one and save it
				let newGUID = uuidv4().toUpperCase();
				store.commit('api/SET_SESSION_ID', newGUID);

				if(window.newrelic){
					newrelic.setCustomAttribute('SessionID', newGUID);
				}
			} else {
				// already set
				store.commit('api/SET_SESSION_ID', store.state.api.sessionId);

				if(window.newrelic){
					newrelic.setCustomAttribute('SessionID', store.state.api.sessionId);
				}
			}
			
			if(config.platformId === config.enums.Platform.CHROME_EXT){
				// Chrome Ext Only

				// check if user is logged in
				chrome.storage.local.get('apiToken', (result) => {
					if(_isEmpty(result)){
						// no api token - go to log in 
						router.push({
							name: 'Login'
						}).catch(()=>{});
						return resolve();
					} else {
						// already set, just return what you have
						return resolve(result.apiToken);
					}
				});

			} else {
				// AddIn and SPA
				if(store.state.api.token === ''){
					// Api Token is blank / hasn not been set
					if(config.platformId === config.enums.Platform.ADD_IN){
						router.push({
							name: 'Login'
						}).catch(()=>{});
					} else if(config.platformId === config.enums.Platform.ONLINE){
						if(config.isDev){
							router.push({
								name: 'Login'
							}).catch(()=>{});
						} else {
							// i have to use a hard redirect here to get to Public from App
							window.location = config.appUrl + '/Public/#/login';
						} 
					}
	
				} else {
					// Token already in vuex store, no problem
					return resolve(store.state.api.token);
	
				}//e:if:else:store.state.api.token

			}//e:if:else:platformId

		}//e:if:else:passToken
		
	});//e:Promise
}