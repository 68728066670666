/*
- AS_FullLogout
-- reset or clear everything from the store
-- 2023.03.22
*/

import config from '@/config';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// store.commit('loaders/ADD_ID', 'App');
		
		store.commit('api/SET_TOKEN', '');

		store.commit('api/SET_CUSTOMER_ID', '');

		store.commit('api/SET_SESSION_ID', '');

		store.commit('app/SET_SHOW_ALPHA_FEATURES', false);
		store.commit('app/SET_SHOW_BETA_FEATURES', false);
		
		if(config.platformId === config.enums.Platform.ONLINE){
			store.commit('classes/SET_LIST', []);
			store.commit('paperEdit/autoSave/SET_TIMER_MS', 0);
		}

		store.commit('customer/SET_ALTERNATIVE_EMAILS', []);
		store.commit('customer/SET_CALENDAR_STARTING_DAY_OF_WEEK', 0);
		store.commit('customer/SET_CALENDAR_STARTING_HOUR', 0);
		store.commit('customer/SET_CALENDAR_VIEW_ID', 0);
		store.commit('customer/SET_EMAIL', '');
		store.commit('customer/SET_EXPIRATION_DATE', '');
		store.commit('customer/SET_FIRST_NAME', '');
		store.commit('customer/SET_FULL_NAME', '');
		store.commit('customer/SET_HAS_PRIORITY_SUPPORT', false);
		store.commit('customer/SET_ID', '0');
		store.commit('customer/SET_INSTITUTION', '');
		store.commit('customer/SET_IS_CANCELLED', false);
		store.commit('customer/SET_IS_IMPERSONATING', false);
		store.commit('customer/SET_IS_RECURRING', false);
		store.commit('customer/SET_LAST_NAME', '');
		store.commit('customer/SET_ORGANIZATION_EXPIRATION_DATE', null);
		store.commit('customer/SET_ORGANIZATION_ID', 0);
		store.commit('customer/SET_ORGANIZATION_STATUS_ID', 0);
		store.commit('customer/SET_PAPER_AUTO_SAVE', 0);
		store.commit('customer/SET_RENEWAL_PERIOD', 0);
		store.commit('customer/SET_ROLE', 0);
		store.commit('customer/SET_TIME_ZONE', 0);

		store.commit('LAST_RELOAD_DATE', '');
		store.commit('LAST_SYNC_DATE', '');
		
		store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
		store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
		store.commit('references/CLEAR_RECENT_TYPE_IDS');
		store.commit('researchNotes/CLEAR_LIST');

		if(config.platformId === config.enums.Platform.ONLINE){
			store.commit('paperList/CLEAR_LIST');
			store.commit('reminders/SET_LIST', []);
			store.commit('work/SET_LIST', []);
		}

		return resolve();
	});//e:Promise
}