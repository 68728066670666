/*
- ErrS_LoadByRoute
-- 2022.09.22
*/
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';

export default ($opts) => {
	return new Promise((resolve, reject) => {
		// console.log('ErrS_LoadByRoute');

		let alertText = 'There was a problem loading this item, please try again.';
		let confirmButtonText = 'Ok';
		let pushToName = 'Dashboard';
		
		// use the current route and $opts to set text and redirect names
		if($opts.entityType === 'Reference'){
			switch(router.currentRoute.name){
				// Paper Edit > Editor Tab
				case 'EditorEditReference':
					alertText = 'There was a problem finding this reference.';
					confirmButtonText = 'Back to References & Citaions';
					pushToName = 'EditorReferencesCitations';
					break;
				// Paper Edit > Organize Tab (modal)
				case 'PaperTabResearchEditReferenceBody':
					alertText = 'There was a problem finding this reference.';
					pushToName = 'X';
					break;
				// Reference Edit > All Tabs
				case 'ReferenceTabDetails':
				case 'ReferenceTabCitations':
				case 'ReferenceTabPapers':
				case 'ReferenceTabResearchNotes':
					alertText = 'There was a problem finding this reference.';
					confirmButtonText = 'Back to Reference List';
					pushToName = 'ReferenceList';
					break;
			}
		} else if($opts.entityType === 'Paper'){
			alertText = 'There was a problem finding this paper.';
			confirmButtonText = 'Back to Dashboard';
			pushToName = 'Dashboard';
		}
		
		// sweet alert 
		Swal.fire({
			allowOutsideClick: false,
			buttonsStyling: false,
			title: 'Error',
			text: alertText,
			icon: 'error',
			confirmButtonText: confirmButtonText,
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: true,
			customClass:{
				confirmButton: 'btn btn-outline-danger',
			},
		}).then((result) => {
			if (result.value) {
				store.commit('loaders/REMOVE_ID', 'App');

				if(pushToName === 'X'){
					// close route modal
					window.$vm.emitter.emit('closeRouteModal')
				} else {
					if(pushToName !== router.currentRoute.name){
						// perform redirect only if it doesnt' match where you already 
						router.push({
							name: pushToName
						}).catch(()=>{});
					}
				}
			}
		});

		resolve();
	});//e:Promise			
}
